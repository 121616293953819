const state = {
    data : {}
}
const mutations = {
    SET_DATA(state,payload) {
        state.data = payload
    }
}
const actions = {
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth': body.token},
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return false
        }
    },

    // QUESTION BANKS
    async GET_QUESTION_BANK_EDIT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/edit/${body.uuid}/${body.lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async ADD_QUESTION_BANK({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/new`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async EDIT_QUESTION_BANK({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/edit`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async ADD_EDIT_BANK_QUESTION({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/question/add`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async ADD_EDIT_BANK_ANSWER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/question/answer`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async GET_COURSE_QUESTION_BANKS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_banks/course/${body.uuid}/${body.lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async DELETE_QUESTION_ANSWER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/question/answer/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async DELETE_QUESTION({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/question/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async COPY_QUESTION({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/question/copy/${body}`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },


    // TESTS ADMIN
    async GET_STUDENT_TEST_ADMIN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`test/student_result/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },


    // old ajax
    async SAVE_QUESTION({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`tests/bank/question/edit`, method:'PUT',data: body});
    },

    // TESTS
    async CREATE_TEST_QUESTION_SETS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`test/new/question_sets`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async SAVE_TEST({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`test`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },

    async PREVIEW_TEST({dispatch,rootState},body) {
        let uuid = body
        let lang = ''
        if (typeof body === 'object') {
            uuid = body.uuid
            lang =`/${body.lang}`
        }
        return await dispatch('ajax/SEND_NEW',
            {
                route:`test/preview/${uuid}${lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root:true});
    },


    async GET_TESTS({dispatch,rootState}) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'tests', method: 'GET'})
    },
    async GET_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body}`, method: 'GET'})
    },
    async DELETE_TESTS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `tests`, method: 'DELETE', data: body})
    },

    // student

    async GET_STUDENT_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body}/student`, method: 'GET'})
    },
    async START_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body.testId}/start`, method: 'POST',data:body.review})
    },
    async SET_ANSWER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body.test}/answer`, method: 'POST',data: body.question})
    },
    async SUBMIT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `test/${body.testId}/submit`, method: 'POST',data:body.data})
    },

    //technical
    async GET_TESTS_SELECT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'tests/selectData', method: 'POST',data:body})
    },



}

export default {
    namespaced:true,
    state,
    mutations,
    actions
}